import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import "./BridgeStyles.css";
import movingImageSrc from "./images/pluglogobg.png";

// Define networks using a configuration object.
const networks = {
  ETHEREUM: {
    id: "eth",
    name: "Ethereum",
    rpcUrl: "https://mainnet.infura.io/v3/7263b87e7c1040f8a8df22cef3f69def",
    tokenAddress: "0x231A6BD8eB88Cfa42776B7Ac575CeCAf82bf1E21",
    chainId: "0x1", // Wallet chain ID for Ethereum Mainnet
  },
  BASE: {
    id: "base",
    name: "Base",
    rpcUrl: "https://mainnet.base.org",
    tokenAddress: "0xDe737ab7b9DfcB6B1A3b4bab5365517e3cf15e75",
    chainId: "0x2105", // Wallet chain ID for Base Mainnet
  },
  BERACHAIN: {
    id: "berachain",
    name: "Berachain",
    rpcUrl: "https://rpc.berachain.com",
    tokenAddress: "0x231A6BD8eB88Cfa42776B7Ac575CeCAf82bf1E21",
    chainId: "0x138de",
  },
};

// Create a mapping from network id to network configuration
const networkById = {};
Object.values(networks).forEach((net) => {
  networkById[net.id] = net;
});

// Aliases for token addresses (for Ethereum and Base)
const ethTokenAddress = networks.ETHEREUM.tokenAddress;
const baseTokenAddress = networks.BASE.tokenAddress;
const berachainTokenAddress = networks.BERACHAIN.tokenAddress;

// LayerZero chain IDs (these are independent of wallet chain IDs)
// Update these values as needed.
const ethChainId = 101; // Ethereum Mainnet LayerZero ID
const baseChainId = 184; // Base Mainnet LayerZero ID
const berachainChainId = 362; // Berachain LayerZero ID

const layerZeroChainIds = {
  eth: ethChainId,
  base: baseChainId,
  berachain: berachainChainId,
};

const tokenAbi = [
  "function balanceOf(address owner) view returns (uint256)",
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function estimateSendFee(uint16 dstChainId, bytes32 toAddress, uint256 amount, bool useZro, bytes calldata adapterParams) external view returns (uint256 nativeFee, uint256 zroFee)",
  "function sendFrom(address _from, uint16 _dstChainId, bytes32 _toAddress, uint256 _amount, uint256 _minAmount, tuple(address refundAddress, address zroPaymentAddress, bytes adapterParams) _callParams) external payable",
];

const Bridge = () => {
  const { address, isConnected } = useAccount();

  // Adapter parameters and minimum amount (unchanged)
  const adapterParams = ethers.solidityPacked(
    ["uint16", "uint256"],
    [1, 500000]
  );
  const minAmount = ethers.parseUnits("8", 18);

  // Initialize with Ethereum as source and bera as destination.
  // (State holds the network id: "eth", "base", or "berachain")
  const [sourceNetwork, setSourceNetwork] = useState(networkById.eth.id);
  const [destinationNetwork, setDestinationNetwork] = useState(
    networkById.berachain.id
  );

  // Balances keyed by network id.
  const [balances, setBalances] = useState({
    eth: null,
    base: null,
    berachain: null,
  });
  const [amountToBridge, setAmountToBridge] = useState("");

  const [isTransactionPending, setIsTransactionPending] = useState(false);
  const [images, setImages] = useState([]); // Animated images for background
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false);
  const [transactionError, setTransactionError] = useState(false);

  // Generate random image properties for animation.
  const generateRandomImage = () => {
    return {
      id: Math.random(),
      size: Math.random() * 20 + 20, // between 20px and 40px
      speed: Math.random() * 5 + 3, // between 3s and 8s
      rotationSpeed: Math.random() * 5 + 3,
      topPosition: Math.random() * 60 + 10, // between 10% and 70%
    };
  };

  useEffect(() => {
    const spawnImages = () => {
      const newImages = [];
      for (let i = 0; i < 15; i++) {
        newImages.push(generateRandomImage());
      }
      setImages(newImages);
    };
    spawnImages();
    const interval = setInterval(spawnImages, 30000); // every 30 seconds
    return () => clearInterval(interval);
  }, []);

  const handleSourceChange = (e) => {
    const selected = e.target.value;
    setSourceNetwork(selected);
    if (selected === destinationNetwork) {
      // If the same network is chosen for both source and destination, switch one.
      setDestinationNetwork(
        selected === networkById.eth.id
          ? networkById.base.id
          : selected === networkById.base.id
          ? networkById.eth.id
          : networkById.eth.id
      );
    }
  };

  const handleDestinationChange = (e) => {
    const selected = e.target.value;
    setDestinationNetwork(selected);
    if (selected === sourceNetwork) {
      setSourceNetwork(
        selected === networkById.eth.id
          ? networkById.base.id
          : selected === networkById.base.id
          ? networkById.eth.id
          : networkById.eth.id
      );
    }
  };

  // Fetch balances for ETH, Base, and Berachain.
  // Each network is fetched in its own try/catch so that one failure does not block the others.
  useEffect(() => {
    const fetchBalances = async (addr) => {
      if (!addr) return;
      const newBalances = { eth: null, base: null, berachain: null };

      // Fetch ETH balance
      try {
        const ethProvider = new ethers.JsonRpcProvider(networkById.eth.rpcUrl);
        const ethContract = new ethers.Contract(
          networkById.eth.tokenAddress,
          tokenAbi,
          ethProvider
        );
        const ethBalance = await ethContract.balanceOf(addr);
        newBalances.eth = parseFloat(ethers.formatEther(ethBalance));
      } catch (err) {
        console.error("Error fetching ETH balance:", err);
      }

      // Fetch Base balance
      try {
        const baseProvider = new ethers.JsonRpcProvider(
          networkById.base.rpcUrl
        );
        const baseContract = new ethers.Contract(
          networkById.base.tokenAddress,
          tokenAbi,
          baseProvider
        );
        const baseBalance = await baseContract.balanceOf(addr);
        newBalances.base = parseFloat(ethers.formatEther(baseBalance));
      } catch (err) {
        console.error("Error fetching Base balance:", err);
      }

      // Fetch Berachain balance (same as the others)
      try {
        const berachainProvider = new ethers.JsonRpcProvider(
          networkById.berachain.rpcUrl
        );
        const berachainContract = new ethers.Contract(
          networkById.berachain.tokenAddress,
          tokenAbi,
          berachainProvider
        );
        const berachainBalance = await berachainContract.balanceOf(addr);
        newBalances.berachain = parseFloat(
          ethers.formatEther(berachainBalance)
        );
      } catch (err) {
        console.error("Error fetching Berachain balance:", err);
        newBalances.berachain = 0;
      }

      setBalances(newBalances);
    };

    if (isConnected && address) {
      fetchBalances(address);
    } else {
      setBalances({ eth: null, base: null, berachain: null });
    }
  }, [isConnected, address]);

  // Helper to get the current wallet chainId.
  const getCurrentNetwork = async () => {
    if (window.ethereum) {
      try {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        return chainId;
      } catch (err) {
        console.error("Error fetching current network:", err);
        return null;
      }
    } else {
      console.error("Ethereum provider is not available.");
      return null;
    }
  };

  // Determine destination address.
  // For Ethereum, we use a fixed token address; otherwise, we use the user’s address.
  const getDestinationAddress = () => {
    return ethers.zeroPadValue(address, 32);
  };

  // Generic fee estimation using the source network’s contract.
  const estimateFees = async (srcNetwork, destNetwork, amount) => {
    if (!address) return ethers.parseUnits("0.01", 18);
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(
      networkById[srcNetwork].tokenAddress,
      tokenAbi,
      signer
    );
    try {
      const [nativeFee] = await tokenContract.estimateSendFee(
        layerZeroChainIds[destNetwork],
        getDestinationAddress(destNetwork),
        ethers.parseEther(amount.toString()),
        false,
        adapterParams
      );
      return nativeFee;
    } catch (error) {
      console.error(
        `Error estimating fees from ${srcNetwork} to ${destNetwork}:`,
        error
      );
      return ethers.parseUnits("0.01", 18);
    }
  };

  // Generic function to send tokens between networks.
  const sendTokens = async (srcNetwork, destNetwork, amount) => {
    if (!address) {
      console.error("No address available");
      return;
    }
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const tokenContract = new ethers.Contract(
      networkById[srcNetwork].tokenAddress,
      tokenAbi,
      signer
    );
    try {
      const nativeFee = await estimateFees(srcNetwork, destNetwork, amount);
      console.log(`nativeFee for ${srcNetwork} to ${destNetwork}: `, nativeFee);
      setIsTransactionPending(true);
      setTransactionError(false);

      const tx = await tokenContract.sendFrom(
        address,
        layerZeroChainIds[destNetwork],
        getDestinationAddress(destNetwork),
        ethers.parseEther(amount.toString()),
        ethers.parseUnits("8", 18),
        {
          refundAddress: address,
          zroPaymentAddress: ethers.ZeroAddress,
          adapterParams: adapterParams,
        },
        { value: nativeFee, gasLimit: 300000 }
      );

      await tx.wait();
      setIsTransactionSuccessful(true);
      setIsTransactionPending(false);
    } catch (error) {
      console.error("Error sending tokens:", error);
      setIsTransactionSuccessful(false);
      setTransactionError(true);
      setIsTransactionPending(false);
    }
  };

  // Switch network if the wallet is not on the desired source network.
  const switchNetwork = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
    } catch (error) {
      if (error.code === 4902) {
        console.error("Network not available in your wallet.");
      } else {
        console.error("Error switching network:", error);
      }
    }
  };

  const bridgeButton = async () => {
    if (!isConnected || !address) {
      console.error("Wallet not connected");
      return;
    }
    const currentNet = await getCurrentNetwork();
    if (
      sourceNetwork === networkById.eth.id &&
      currentNet !== networkById.eth.chainId
    ) {
      await switchNetwork(networkById.eth.chainId);
    } else if (
      sourceNetwork === networkById.base.id &&
      currentNet !== networkById.base.chainId
    ) {
      await switchNetwork(networkById.base.chainId);
    } else if (
      sourceNetwork === networkById.berachain.id &&
      currentNet !== networkById.berachain.chainId
    ) {
      await switchNetwork(networkById.berachain.chainId);
    }

    if (sourceNetwork === destinationNetwork) {
      console.error("Source and destination networks cannot be the same.");
      return;
    }
    await sendTokens(sourceNetwork, destinationNetwork, amountToBridge);
  };

  return (
    <div className="bridge-centered-content">
      {!isConnected ? (
        <p className="connect-wallet-message">
          Please connect your wallet to use the bridge.
        </p>
      ) : (
        <div>
          <div className="bridge-menu">
            {/* Floating Images Container */}
            <div className="image-container">
              {images.map((image) => (
                <img
                  key={image.id}
                  src={movingImageSrc}
                  className="moving-image"
                  style={{
                    width: `${image.size}px`,
                    height: `${image.size}px`,
                    top: `${image.topPosition}%`,
                    animation: `moveAcross ${image.speed}s linear infinite, rotate ${image.rotationSpeed}s linear infinite`,
                  }}
                  alt="Moving Logo"
                />
              ))}
            </div>
            <div className="network-card">
              <div className="network-box">
                <select
                  value={sourceNetwork}
                  onChange={handleSourceChange}
                  className="bridge-dropdown"
                >
                  <option value={networkById.eth.id}>
                    {networkById.eth.name}
                  </option>
                  <option value={networkById.base.id}>
                    {networkById.base.name}
                  </option>
                </select>
                <p className="network-balance">
                  Balance:{" "}
                  {balances[sourceNetwork] !== null
                    ? balances[sourceNetwork] < 0.01
                      ? "0"
                      : balances[sourceNetwork].toFixed(2)
                    : "Loading..."}
                </p>
              </div>
            </div>
            <div className="arrow">
              {isTransactionPending ? <div className="spinner"></div> : "→"}
            </div>
            <div className="network-card">
              <div className="network-box">
                <select
                  value={destinationNetwork}
                  onChange={handleDestinationChange}
                  className="bridge-dropdown"
                >
                  <option value={networkById.eth.id}>
                    {networkById.eth.name}
                  </option>
                  <option value={networkById.base.id}>
                    {networkById.base.name}
                  </option>
                  <option value={networkById.berachain.id}>
                    {networkById.berachain.name}
                  </option>
                </select>
                <p className="network-balance">
                  Balance:{" "}
                  {balances[destinationNetwork] !== null
                    ? balances[destinationNetwork] < 0.01
                      ? "0"
                      : balances[destinationNetwork].toFixed(2)
                    : "Loading..."}
                </p>
              </div>
            </div>
          </div>

          <div className="bridge-input-container">
            <div className="input-card">
              <input
                type="number"
                value={amountToBridge}
                onChange={(e) => setAmountToBridge(e.target.value)}
                placeholder="Enter amount"
                className="bridge-input"
              />
              <button
                className="max-button"
                onClick={() => setAmountToBridge(balances[sourceNetwork] || 0)}
              >
                Max
              </button>
            </div>
          </div>

          <button className="bridge-button" onClick={bridgeButton}>
            {isTransactionPending ? <div className="spinner"></div> : "Bridge"}
          </button>

          {/* Success and Error Messages */}
          <div>
            {isTransactionSuccessful && (
              <div className="success-message">
                Bridge initiated. Follow progress here:{" "}
                <a
                  href={`https://layerzeroscan.com/address/${address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`https://layerzeroscan.com/address/${address}`}
                </a>
              </div>
            )}
            {transactionError && (
              <div className="error-message">
                Transaction Failed. Please try again.
              </div>
            )}
          </div>
        </div>
      )}
      {/* Warning text at the bottom */}
      <div
        className="warning-text"
        style={{
          marginTop: "40px",
          textAlign: "center",
          color: "red",
          fontSize: "30px",
        }}
      >
        Warning: sending $PLUG to BERACHAIN is permanent
      </div>
    </div>
  );
};

export default Bridge;
